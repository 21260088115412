import { Component, OnInit, OnDestroy } from "@angular/core";
import { CachingService } from "src/app/core/services/caching/caching.service";
import { LanguageService } from "src/app/core/services/language/language.service";
import { Subscription } from 'rxjs';

@Component({
  selector: "app-language",
  templateUrl: "./language.component.html",
  styleUrls: ["./language.component.scss"],
})
export class LanguageComponent implements OnInit, OnDestroy {
  showLang: boolean = false;
  element: HTMLElement;
  savedLanguage: string;
  flagvalue: string;
  countryName: string;
  valueset: string;
  listLang: any[];
  currentLang: any;
  private langSubscription: Subscription;

  constructor(
    private languageService: LanguageService,
    public cachingService: CachingService
  ) {}

  ngOnInit(): void {
    // Use safe subscription
    this.langSubscription = this.languageService.showLanguagesList.subscribe(
      (show) => (this.showLang = show),
      (error) => console.error('Language subscription error:', error)
    );
    
    this.listLang = this.languageService.listLang;
    this.setAppLanguage();
  }

  ngOnDestroy(): void {
    // Clean up subscription
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }

  private setDocumentDirection(lang: string): void {
    try {
      const htmlElement = document.documentElement;
      const direction = lang === 'en' ? 'ltr' : 'rtl';
      const addClass = lang;
      const removeClass = lang === 'en' ? 'ar' : 'en';

      htmlElement.setAttribute('dir', direction);
      htmlElement.setAttribute('lang', lang);
      document.body.classList.add(addClass);
      document.body.classList.remove(removeClass);
    } catch (error) {
      console.error('Error setting document direction:', error);
    }
  }

  setAppLanguage(): void {
    try {
      this.element = document.documentElement;
      this.savedLanguage = this.cachingService.get("lang") || 'en'; // Default to 'en'

      const val = this.listLang.filter((x) => x.lang === this.savedLanguage);
      
      if (val.length === 0) {
        this.valueset = "assets/images/flags/us.jpg";
      } else {
        this.currentLang = val[0];
        this.countryName = val[0].text;
        this.flagvalue = val[0].flag;
      }

      this.cachingService.set("lang", this.savedLanguage);
      this.setDocumentDirection(this.savedLanguage);
    } catch (error) {
      console.error('Error in setAppLanguage:', error);
    }
  }

  setLanguage(language: any): void {
    if (!language) return;

    try {
      // Update local values
      this.countryName = language.text;
      this.flagvalue = language.flag;
      this.savedLanguage = language.lang;

      // Cache the selection
      this.cachingService.set("lang", language.lang);

      // Update service
      this.languageService.setLanguage(language.lang);

      // Update document direction
      this.setDocumentDirection(language.lang);

      // Close language selector
      this.showLang = false;

      // Preload fonts for the selected language
      this.preloadFonts(language.lang);
    } catch (error) {
      console.error('Error setting language:', error);
    }
  }

  private preloadFonts(lang: string): void {
    const fonts = {
      'ar': ['Cairo:400,500,600,700'],
      'en': ['Poppins:400,500,600,700']
    };

    try {
      const fontFamily = fonts[lang];
      if (fontFamily) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = `https://fonts.googleapis.com/css2?family=${fontFamily}&display=swap`;
        document.head.appendChild(link);
      }
    } catch (error) {
      console.error('Error preloading fonts:', error);
    }
  }
}