import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AngularFireMessaging } from "@angular/fire/messaging";

@Injectable({
  providedIn: "root",
})
export class FirebaseMessagesService {
  currentMessage = new BehaviorSubject(null);
  firebaseToken: string;
  private isFirebaseSupported: boolean = false;

  constructor(private angularFireMessaging: AngularFireMessaging) {
    // Check support before initializing
    this.checkBrowserSupport();
    
    if (this.isFirebaseSupported) {
      this.initializeFirebase();
    }
  }

  private checkBrowserSupport(): void {
    this.isFirebaseSupported = (
      'Notification' in window &&
      'serviceWorker' in navigator &&
      'PushManager' in window
    );
  }

  private initializeFirebase(): void {
    try {
      this.angularFireMessaging.onMessage((_messaging) => {
        this.currentMessage.next(_messaging);
      });
      this.receiveMessage();
    } catch (error) {
      console.warn('Firebase messaging initialization failed:', error);
    }
  }

  async requestPermission() {
    if (!this.isFirebaseSupported) {
      console.warn('Push notifications are not supported in this browser');
      return false;
    }

    try {
      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        console.warn('Notification permission was not granted.');
        return false;
      }

      return await new Promise<boolean>((resolve) => {
        this.angularFireMessaging.requestToken.subscribe(
          (token) => {
            this.firebaseToken = token;
            resolve(true);
          },
          (err) => {
            console.warn("Unable to get permission to notify.", err);
            resolve(false);
          }
        );
      });
    } catch (error) {
      console.warn('Error requesting notification permission:', error);
      return false;
    }
  }

  receiveMessage() {
    if (!this.isFirebaseSupported) return;

    try {
      this.angularFireMessaging.messages.subscribe(
        (payload) => {
          this.currentMessage.next(payload);
        },
        (error) => {
          console.warn('Error receiving message:', error);
        }
      );
    } catch (error) {
      console.warn('Error setting up message receiver:', error);
    }
  }
}